export const defaultEditorContent = {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "Your Private notes, enter the page in the text box above to make a new page." }],
    }
  ],
};
